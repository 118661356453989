<style lang="less" scoped>
  @import "../../assets/css/variables";
  .page-container{
    height: 100%;
  }
</style>

<template keep-alive>

  <div class="page-container">
    <error :defultCode="404"></error>
  </div>

</template>

<script>

  import Error from "./error.vue";
  export default {
    data() {
      return {}
    },
    components: {
      Error
    },
    created(){

    }
  }

</script>
