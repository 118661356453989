<style lang="less" scoped>
  @import "../../assets/css/variables";

  .content {
    height: 100%;
    .error-wrapper {
      position: relative;
      margin: 0 auto;
      width: 561px;
      height: 100%;
      .error-container {
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -150px;
        width: 100%;
        height: 300px;
        .error-image {
          height: 231px;
          width: 200px;
          // background-image: url("~/static/error-sprite.png");
          background-position: 200px 0;
          &.not-found {
            background-position: 0 231px;
          }
        }

        .error-info-container {
          width: 330px;
          text-align: left;
          color: #889aab;
          .title {
            margin-top: 20px;
            font-size: 40px;
            margin-bottom: 40px;
          }
          .info {
            margin-bottom: 40px;
            line-height: 22px;
          }
        }
      }
    }
  }

</style>

<template :is="view">
  <div class="content">
    <div class="error-wrapper">
      <div class="error-container clearfix">
        <div class="error-image pull-left" :style="{'background-image':'url('+errorSprite+')'}"
             :class="{'not-found':code==404}">
        </div>
        <div class="error-info-container pull-right">
          <div class="title">{{message.title}}</div>
          <div class="info">{{message.message}},错误码:{{code}}</div>
          <div class="fn">
            <el-button @click="goHome">&nbsp;&nbsp;返回主页&nbsp;&nbsp;</el-button>
            <el-button @click="goBack">返回上一页</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import errorMap from "../../utils/errormessage";
  import {IMAGE_PATH} from "../../config";
  export default {
    data () {
      return {
        code: 0,
        errorSprite: IMAGE_PATH + 'error-sprite.png',
        message: '未知错误'
      }
    },
    props: {
      defultCode: {
        type: Number,
        default: function () {
          return 0
        }
      },
    },
    methods: {
      goBack: function () {
        window.router.back();
      },
      goHome: function () {
        window.router.push("/index");
      }
    },
    created(){
      this.code = this.$route.query.code || this.defultCode || 0;
      this.message = errorMap.get(this.code) || {title: '未知错误', message: '发生未知错误'};
    }
  }
</script>
