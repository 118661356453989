/**
 * Created by xinsw on 2017/3/20.
 * 错误消息定义
 */

let errorMessageMap = new Map();
errorMessageMap.set(-1, {title: '应用程序错误', message: '暂未开放'});
errorMessageMap.set(400, {title: '应用服务器错误', message: '错误的请求'});
errorMessageMap.set(403, {title: '应用服务器错误', message: '禁止访问'});
errorMessageMap.set(404, {title: '未找到页面', message: '没有找您访问的页面,可能原因:网址失效'});
errorMessageMap.set(405, {title: '服务器禁止访问', message: '可能原因:没有相关权限！'});
errorMessageMap.set(500, {title: '应用服务器错误', message: '应用服务器内部错误'});


export default errorMessageMap;

